import { connect } from 'react-redux';

import { getExperienceFragment } from 'redux/modules/experience-fragments/selectors';

const mapStateToProps = (state, props) => ({
  locationData: getExperienceFragment(state, props.experienceFragmentKey, props.locationName),
});

const mapDispatchToProps = {};

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
